import React from "react";

export const data_3d_design = {
  what_we_do: {
    title: "Szeroki zakres zastosowań projektów 3D",
    text: [
      <>
        <b>Wizualizacje wnętrz</b> – oddaj prawdziwy klimat aranżowanego
        wnętrza. Wyeksponujemy najważniejsze elementy Twojego projektu i
        sprawimy, że Twój klient poczuje się jak w domu.
      </>,
      <>
        <b>Prezentacje produktów i konfiguratory</b> – przywiązujemy wagę do
        detali, a dbałość o każdy szczegół to nasza droga do sukcesu. Dlatego
        proponujemy Ci projekt, który dzięki zastosowaniu technologi 3D zachęci
        Twoich klientów do współpracy.
      </>,
      <>
        <b>Materiały reklamowe i prezentacje</b> – ponieważ marketing nie lubi
        nudy, postaw na nowoczesną reklamę Twojej firmy. Przedstawienie oferty w
        niestandardowy sposób wyróżni ją na tle konkurencji.
      </>,
      <>
        <b>Animacje</b> – w nieszablonowy sposób przedstawimy działalność Twojej
        marki, a przy użyciu odpowiednich elementów graficznych i dźwiękowych
        ciekawie opowiemy historię, którą chcesz przekazać klientom.
      </>,
    ],
  },
  our_process: {
    title: "Nasz proces projektowy",
    steps: [
      {
        title: "Badanie rynku",
        list: [
          "Analiza konkurencji",
          "Poznanie biznesu klienta",
          "Określenie grupy docelowej",
          "Badanie trendów rynkowych",
          "Brief",
        ],
      },
      {
        title: "Planowanie",
        list: [
          "Określenie struktury strony",
          "Opracowanie architektury informacji",
          "Stworzenie makiet",
        ],
      },
      {
        title: "Projektowanie graficzne",
        list: [
          "Połączenie stylu i koncepcji",
          "dopasowanie kolorystyki",
          "dobór odpowiednich materiałów (grafiki, zdjęcia, typografia)",
          "realizacja projektu graficznego",
        ],
      },
    ],
  },
};
